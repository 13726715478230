:root {
  --gradient-blue: linear-gradient(135deg, #0e89af 0%, #00516b 100%);
}

.pme {
  .animate-cross-move {
    animation: cross-move 0.5s forwards;
  }

  .animate-reserve-cross-move {
    animation: reserve-cross-move 0.5s forwards;
  }

  @keyframes cross-move {
    from {
      left: 10px;
      top: 70%;
    }
    to {
      left: calc(100% - 65px);
      top: 15px;
    }
  }

  @keyframes reserve-cross-move {
    from {
      left: calc(100% - 85px);
      top: 15px;
    }
    to {
      left: 20px;
      top: 70%;
    }
  }
}
body {
  @font-face {
    font-family: "SofiaProLight";
    src: url("../src//components/common/change-themes/sofiapro-light.otf")
      format("opentype");
  }
}

.background-container {
  display: flex;
  flex-direction: column;
  background: var(--gradient-blue);
  color: white;
  font-family: "SofiaProLight", sans-serif;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  .login-form {
    width: 500px;
    padding: 2rem;
  }

  .login-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
  }

  .login-logo img {
    width: full;
    max-width: 200px;
    height: auto;
  }
}
.bg-button {
  background-color: rgba(120, 190, 32, 1);
}

.button {
  background-color: rgba(120, 190, 32, 1);
}

.input-bg {
  --tw-bg-opacity: 0.3;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  border-radius: 0.25rem;
}
.line {
  display: block;
  height: 2;
  width: 25px;
  background-color: black;
  margin: 0 auto;
}
.editor-container {
  width: 100%;
  height: 500px;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
}

.learning-section {
  margin-top: 60px;
  background: #3b93ae;
  ul {
    padding-left: unset;
  }

  a {
    text-decoration: unset;
    cursor: pointer;
  }
}

option {
  color: rgb(0 0 0);
  background: white;
}

.pick-user {
  background: linear-gradient(
    90deg,
    rgba(48, 148, 116, 1) 0%,
    rgba(45, 136, 133, 0.5) 87%
  );
}

.header-h3 {
  font-family: "Poppins", sans-serif;
}

.folder-container {
  background: #3b93ae;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.relative .dot {
  background-color: white;
}

/* CSS cho trạng thái khi checkbox được chọn */
.relative .dot.peer-checked {
  transform: translateX(100%);
  background-color: #34d399;
}

//text security
@font-face {
  font-family: text-security-disc;
  src: url("https://raw.githubusercontent.com/noppa/text-security/master/dist/text-security-disc.woff");
}

.verify-input {
  font-family: text-security-disc;
  -webkit-text-security: disc;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

.report-button {
  width: 76px;
  background-color: rgba(0, 0, 0, 0.25);
  min-height: 20px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 0.375rem;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: not-allowed;
}
